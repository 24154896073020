 export function handleExpandCollapse(listName){
    let listId = `#${listName}-list`
    let listPanel = `.${listName}-panel-collapse`
    let listHeading = `.${listName}-panel-heading`

    $(listId).on('show.bs.collapse', listPanel, function () {
        $(`${listPanel}.collapse`).collapse('hide');
        $(this).siblings(listHeading).addClass('active');
    });
    
    $(listId).on('hide.bs.collapse', listPanel, function () {
        $(this).siblings(listHeading).removeClass('active');
    });
}

document.addEventListener("turbo:load", () => {
    $('.gen-header-half-bg').parent().addClass('if-half-bg');

    // BETA ONLY: Selects all elements with the 'ext-link-html' class. For each link not within a '.schd_links' container, 
    // it disables the link by setting 'aria-disabled' to 'true', adding the 'disabled' class, 
    // and preventing the default click behavior.
    const extLinks = document.querySelectorAll('.ext-link-html');
      
    extLinks.forEach(link => {
      if (!link.closest('.schd_links') && !link.closest('.schedule-header-dropdowns')) {
        link.setAttribute('aria-disabled', 'true');
        link.classList.add('disabled');
        link.addEventListener('click', (event) => {
          event.preventDefault();
        });
      }
    });

    document.querySelectorAll('a.disabled').forEach(function(link) {
      link.addEventListener('click', function(event) {
        event.preventDefault();
      });
    });
})
