document.addEventListener('DOMContentLoaded', () => {
  const adjustTableRoles = () => {
    const isMobile = window.innerWidth < 768
    
    // Find all tables inside elements with class 'dyn-tbl', 'interim-schedule-table', or 'schedules'
    const dynTables = document.querySelectorAll('.dyn-tbl table, .interim-schedule-table table, .schedules table')
    
    dynTables.forEach(table => {
      const headerRow = table.querySelector('thead tr')
      
      if (isMobile) {
        // Set table role to list for mobile view
        table.setAttribute("role", "list")
        
        // Hide table header row from screen readers
        if (headerRow) {
          headerRow.setAttribute("aria-hidden", "true")
        }
        
        // Set each data row to role of listitem
        table.querySelectorAll("tbody tr").forEach(tr => {
          tr.setAttribute("role", "listitem")
        })
        
        // No need for th roles in mobile view
        table.querySelectorAll("th").forEach(th => {
          th.removeAttribute("role")
        })
      } else {
        // Add table ARIA roles for desktop view
        table.setAttribute("role", "table")
        
        // Remove aria-hidden from header row
        if (headerRow) {
          headerRow.removeAttribute("aria-hidden")
        }
        
        // Set row roles for desktop view
        table.querySelectorAll("tr").forEach(tr => {
          tr.setAttribute("role", "row")
        })
        
        // Set column header roles for desktop view
        table.querySelectorAll("th").forEach(th => {
          th.setAttribute("role", "columnheader")
        })
      }
    })
  }

  // Run initial adjustment
  adjustTableRoles()
  
  // Set up resize observer to adjust tables when window size changes
  const resizeObserver = new ResizeObserver(() => adjustTableRoles())
  resizeObserver.observe(document.body)
  
  // Set up mutation observer to handle dynamically added tables
  const mutationObserver = new MutationObserver(() => adjustTableRoles())
  mutationObserver.observe(document.body, { 
    childList: true, 
    subtree: true 
  })
})
