// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails";

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("packs/visit_and_learn")
require("packs/expand_collapse")
require("packs/ballots")
require("packs/bills")
require("packs/main_nav_menu")
require("packs/sessions")
require("packs/committees")
require("packs/initiatives")
require("packs/budgets")
require("packs/home")
require("packs/agenda")
require("packs/search")
require("packs/custom_dropdown")
require("common")
require("packs/footer")
require("packs/responsive_tables")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//

window.jQuery = $;
window.$ = $;

//Should this funciton live elsewhere?
$(function(){
    // Plain jquery
    console.log("page loaded");
    // initialize magnific popup
    window.jQuery = $;
    window.$ = $;
})

// import 'bootstrap'
import 'bootstrap/dist/js/bootstrap'
//Should this funciton live elsewhere?
document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  const bannerToggle = document.querySelector('.banner-toggle');
  const bannerText = document.querySelector('.banner-text');

  if (bannerToggle) {
    bannerToggle.addEventListener('click', function() {
      bannerText.classList.toggle('d-none');
      bannerToggle.textContent = bannerText.classList.contains('d-none') ? 'Show Announcement' : 'Hide';
      // Update aria-label attribute
      bannerToggle.setAttribute('aria-label', bannerText.classList.contains('d-none') ? 'Show banner text' : 'Hide banner text');
    });
  }
})

import 'owl.carousel';
//below is duplicate import from top of file
import "@hotwired/turbo-rails"
import ahoy from "ahoy.js"

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import 'controllers'

//require("trix")
//require("@rails/actiontext")
