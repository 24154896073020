import SearchRecordsController from "../controllers/search_records_controller";

window.addEventListener("pageshow", function(event) {
  // close offcanvas menu when navigating back via browser back button
  const offcanvas = document.querySelector(".offcanvas-collapse");
  if (offcanvas) {
    offcanvas.classList.remove("open", "show");
    offcanvas.classList.add("d-none");
    offcanvas.setAttribute("aria-hidden", "true");
  }
});

document.addEventListener("turbo:load", function() {
  const offcanvas = document.querySelector(".offcanvas-collapse");
  if (!offcanvas) return;
  // Store the last toggler button that triggered the menu
  let lastToggler = null;
  // Helper to remove aria-hidden set by trapFocus
  function removeAriaHidden(trigger) {
    if (!trigger) return;
    const toggleType = trigger.getAttribute("data-toggle");
    if (toggleType === "offcanvas") {
      const mainElement = document.querySelector("main");
      if (mainElement) {
        mainElement.removeAttribute("aria-hidden");
      }
    }
  }

  document.querySelectorAll('[data-toggle="offcanvas"]').forEach(button => {
    button.addEventListener("click", function () {
      const isOpen = offcanvas.classList.toggle("open");

      if (isOpen) {
        // Save the toggler for later focus restoration
        lastToggler = this;
        offcanvas.classList.remove("d-none");
        offcanvas.setAttribute("aria-hidden", "false");
        offcanvas.focus();
        // Call the static trapFocus method from the controller
        SearchRecordsController.trapFocus(offcanvas);
      } else {
        offcanvas.classList.add("d-none");
        offcanvas.setAttribute("aria-hidden", "true");
        removeAriaHidden(lastToggler);
        if (lastToggler) {
          lastToggler.focus();
        }
      }
    });
  });

  // Close the offcanvas menu on Escape key press and restore focus
  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape" && offcanvas.classList.contains("open")) {
      offcanvas.classList.remove("open");
      offcanvas.classList.add("d-none");
      offcanvas.setAttribute("aria-hidden", "true");
      removeAriaHidden(lastToggler);
      if (lastToggler) {
        lastToggler.focus();
      }
    }
  });

  // Close the offcanvas menu when the page loads
  if (offcanvas.classList.contains("open")) {
    offcanvas.classList.remove("open");
    offcanvas.classList.add("d-none");
    offcanvas.setAttribute("aria-hidden", "true");
    removeAriaHidden(lastToggler);
  }

  const navItems = document.querySelectorAll('.nav-item.dropdown');

  // Hide other dropdown menus when hovering over a nav item
  navItems.forEach(function (navItem) {
    navItem.addEventListener('mouseenter', function () {
      const currentlyShownDropdown = document.querySelector('.nav-item.dropdown.show');
      if (currentlyShownDropdown && currentlyShownDropdown !== navItem) {
        currentlyShownDropdown.classList.remove('show');
        const currentlyShownLink = currentlyShownDropdown.querySelector('.nav-link');
        if (currentlyShownLink) {
          currentlyShownLink.setAttribute('aria-expanded', 'false');
        }
        const dropdownMenu = currentlyShownDropdown.querySelector('.dropdown-menu');
        if (dropdownMenu) {
          dropdownMenu.classList.remove('show');
        }
      }
    });
  });
});
