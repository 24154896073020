import { Controller } from "@hotwired/stimulus"
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import compact from 'lodash/compact';

export default class extends Controller {
  static targets = [
    "searchFiltersBlock",
    "toggleShowFiltersLink",
    "filterDetailsWrapper",
    "form",
    "desktopToggleShowFiltersLink",
  ]

  initialize() {
    this.debounceSubmit = debounce((e) => {
      this.requestSubmit(e);
    }, 300)
  }

  connect() {
    this.setupResizeListener();
    localStorage.removeItem('showAll');

    const screenWidth = window.innerWidth;
    if (screenWidth <= 991) {
      this.hideFilters();
      document.querySelectorAll(".gen-filters-block-main .all-bills-panel-collapse").forEach(function (element) {
        element.classList.remove("show");
        const button = document.querySelector(`[aria-controls="${element.id}"]`);
        if (button) {
          button.classList.add("collapsed");
          button.setAttribute("aria-expanded", "false");
        }
      });

      this.searchFiltersBlockTarget.setAttribute('tabindex', '0');
    } else {
      this.searchFiltersBlockTarget.removeAttribute('tabindex');
    }

    this.handlePopState = (e) => {
      Turbo.visit(window.location, { action: 'replace' });
      if (!e.state.customHistory) {
        window.removeEventListener('popstate', this.handlePopState);
      }
    };
    window.addEventListener('popstate', this.handlePopState);

    this.initializeTurboStreamEvents();
    this.addFocusRestorationListener();
  }

  toggleShowFilters() {
    const screenWidth = window.innerWidth;
    this.searchFiltersBlockTarget.classList.toggle("show");
    const isFiltersBlockVisible = this.searchFiltersBlockTarget.classList.contains("show");
    if (screenWidth <= 991 && isFiltersBlockVisible) {
      this.constructor.trapFocus(this.searchFiltersBlockTarget);
    }
    const filters = $('#show-search-filters');
    filters.val(!isFiltersBlockVisible);
    this.toggleFilterButtonInnerHTML();
    this.searchFiltersBlockTarget.focus();
  }

  hideFilters() {
    this.searchFiltersBlockTarget.classList.remove("show");
    this.searchFiltersBlockTarget.classList.add("d-none");
    document.body.classList.remove('mobile-offcanvas-active');
    localStorage.removeItem('focusedElement');
    // remove aria-hidden attribute from .search-results-block if it was set
    const searchResults = document.querySelector('.search-results-block');
    searchResults?.hasAttribute('aria-hidden') && searchResults.removeAttribute('aria-hidden');
  }

  clearSpecificFilter = ({ currentTarget }) => {
    const currentCheckboxLabel = currentTarget.dataset.name;
    const checkboxes = document.querySelectorAll('.gen-filter-cb');

    checkboxes.forEach((checkbox) => {
      const label = checkbox.closest('label');
      if (label && label.querySelector('span').textContent.trim() === currentCheckboxLabel) {
        checkbox.checked = false;
      }
    });

    let currentPrimeSponsor = document.querySelector('.prime-sponsor-btn.selected');

    if (currentPrimeSponsor && currentCheckboxLabel === currentPrimeSponsor.innerText.trim()) {
      $(currentPrimeSponsor).removeClass('selected');
      currentPrimeSponsor.removeAttribute('disabled');

      let primeSponsors = {
        currentTarget: currentPrimeSponsor.parentNode.lastElementChild,
      }
      this.clearSelectedPrimeSponsor(primeSponsors);
    }

    this.formTarget.requestSubmit();
    this.updateQueryString(this.formTarget);
    this.scrollToElement('#search-results-container');
    this.hideInformationBanner();
  }

  clearAllFilters() {
    $('.gen-filter-cb').prop('checked', false);

    let currentPrimeSponsor = document.querySelector('.prime-sponsor-btn.selected');

    if (currentPrimeSponsor) {
      $(currentPrimeSponsor).removeClass('selected');
      currentPrimeSponsor.removeAttribute('disabled');

      let primeSponsors = {
        currentTarget: currentPrimeSponsor.parentNode.lastElementChild,
      }
      this.clearSelectedPrimeSponsor(primeSponsors);
    }

    this.formTarget.requestSubmit();
    this.updateQueryString(this.formTarget);
    this.scrollToElement('#search-results-container');
    this.hideInformationBanner();
  }

  removeSiblings(event) {
    if (document.querySelector('.prime-sponsor-btn.selected')) return;
    localStorage.removeItem('focusedElement');
    event.preventDefault();
    const clickedLink = event.currentTarget;
    let newInput= document.createElement("input");
    newInput.type="hidden";
    newInput.name= 'bill_sponsorships[]'
    newInput.value = clickedLink.dataset.value;

    this.formTarget.append(newInput);
    this.formTarget.requestSubmit();
    this.updateQueryString(this.formTarget);
    this.restoreFocusOnFiltersModal(`x-${event.target.id}`);
    this.hideInformationBanner();
  }

  clearSelectedPrimeSponsor = ({ currentTarget }) => {
    const sponsorshipInput = $(this.formTarget).find('input[type="hidden"][name="bill_sponsorships[]"]');

    if (sponsorshipInput.length) {
      sponsorshipInput.remove();
    }

    this.formTarget.requestSubmit();
    this.updateQueryString(this.formTarget);
    currentTarget.parentNode.lastElementChild.remove();
    this.restoreFocusOnFiltersModal(currentTarget.id.slice(2));
    this.hideInformationBanner();
  }

  submitFormOnCheckedChange(event) {
    const target = event.target;
    if (event.type === 'click' || event.key === 'Enter') {
      let pageInput = $(target).closest('nav').find('input[type="hidden"][name="page"]');

      if (target.innerText.includes('Next')) {
        pageInput.val(parseInt(pageInput.val()) + 1);
      } else if (target.innerText.includes('Prev')) {
        pageInput.val(parseInt(pageInput.val()) - 1);
      } else {
        pageInput.val(target.innerText);
      }

      if (event.key === 'Enter' || target.id === 'bill-search-submit-button' || target.type === 'checkbox') {
        const pageNumberInput = $('#kaminari-page-number');
        pageNumberInput.val('1');
      }

      const legislatorBills = document.getElementById("legislator-bills-block");
      if (legislatorBills) {

      } else {
        //If a search string is submitted, update the sort dropdown to "Most Relent."
        if(target.id === 'bill-search-submit-button' || target.id === 'bill_search_input'){
          $("#sort").val("Most Relevant")
        }
      }

      this.updateQueryString(this.formTarget);
    }
  }

  updateSortFilterList = (event) => {
    this.formTarget.requestSubmit();
    this.updateQueryString(this.formTarget);
    localStorage.setItem('focusedElement', `${event.target.id}-ts-control`);
    this.scrollToElement('#search-results-container');
  }

  toggleShowFilterOptions({ currentTarget }) {
    const parentElement = currentTarget.parentElement;
    const checkBoxWrapperElements = parentElement.querySelectorAll('.search-filters-checkbox');
    const itemsCount = currentTarget.dataset.itemsCount - 6;
    const parentContainer = currentTarget.closest('.gen-filters-block-main');

    if (currentTarget.classList.contains('show-less')) this.scrollToElement(parentContainer);
    $(checkBoxWrapperElements).toggleClass('search-filters-checkbox-toggle')
    this.handleToggleShowFilterOptionsBtnText(parentElement, currentTarget, itemsCount);
    let showAll = JSON.parse(localStorage.getItem('showAll')) || {};
    showAll[currentTarget.id] = currentTarget.classList.contains('show-less');
    localStorage.setItem('showAll', JSON.stringify(showAll));
  }

  handleToggleShowFilterOptionsBtnText(elem, showFiltersBtn, itemsCount) {
    const lessCheckBoxWrapperElements = elem.querySelectorAll('.search-filters-checkbox-toggle');
    const btnIcon = showFiltersBtn.querySelector('.show-all-filter-options-link-icon');
    const btnText = showFiltersBtn.querySelector('.show-all-filter-options-link-text');

    if (lessCheckBoxWrapperElements.length > 0) {
      if (btnIcon?.children[0]) {
        btnIcon.children[0].classList.replace('fa-minus', 'fa-plus');
      }
      if (btnText) {
        btnText.textContent = `Show all (${itemsCount})`;
        showFiltersBtn.classList.remove('show-less');
      }
    } else {
      if (btnIcon?.children[0]) {
        btnIcon.children[0].classList.replace('fa-plus', 'fa-minus');
      }
      if (btnText) {
        btnText.textContent = 'Show less';
        showFiltersBtn.classList.add('show-less');
      }
    }
  }

  searchFilterOptions({ currentTarget }) {
    const parentContainer = currentTarget.closest('.filter-options-container');
    const checkBoxWrapperElements = parentContainer.querySelectorAll('.search-filters-checkbox:not(.d-none)');
    const showAllBtn = parentContainer.querySelector('.show-all-filter-options-link');
    const inputField = currentTarget.closest('.input-group').querySelector('.filter-options-input');
    let searchTerm = (inputField.value)?.toLowerCase();
    const noResultsMessage = parentContainer.querySelector('#no-results-message');

    if (showAllBtn) {
      if (searchTerm === '') {
        showAllBtn.style.display = '';
        $(checkBoxWrapperElements).addClass('search-filters-checkbox-toggle');
        let itemsCount = checkBoxWrapperElements.length - 6;
        this.handleToggleShowFilterOptionsBtnText(parentContainer, showAllBtn, itemsCount);
      } else {
        $(checkBoxWrapperElements).removeClass('search-filters-checkbox-toggle');
        showAllBtn.style.display = 'none';
      }
    }

    let anyMatch = false;
    checkBoxWrapperElements.forEach((cb) => {
      const labelElement = cb.querySelector('.search-filter-label');
      const label = labelElement?.innerText?.toLowerCase() || '';
      const labelWords = label.split(' ');
      const searchTermWords = searchTerm.split(' ');

      const isContain = searchTermWords.every(term =>
        labelWords.some(word => word.startsWith(term))
      );
      if (isContain) {
        cb.style.display = '';
        anyMatch = true;
      } else {
        cb.style.display = 'none';
      }
    });

    if (!anyMatch && searchTerm !== '') {
      noResultsMessage.classList.remove('d-none');
    } else {
      noResultsMessage.classList.add('d-none');
    }
  }

  clearSearch({ currentTarget }) {
    let input = currentTarget.closest('.filter-options-container').querySelector('.filter-options-input');
    input.value = ''
    input.dispatchEvent(new Event('input'));
  }

  toggleFilterButtonInnerHTML() {
    const screenWidth = window.innerWidth;
    const isBlockVisible = this.searchFiltersBlockTarget.classList.contains("show");
    document.body.classList.toggle('mobile-offcanvas-active', isBlockVisible);

    if (isBlockVisible) {
      this.searchFiltersBlockTarget.classList.remove("d-none");
    } else {
      this.searchFiltersBlockTarget.classList.add("d-none");
    }

    if (screenWidth > 991) {
      this.desktopToggleShowFiltersLinkTarget.innerText = isBlockVisible ? "Hide filters" : "Show filters";
      !isBlockVisible ? this.filterDetailsWrapperTarget.classList.remove('flex-xl-row', 'flex-column-reverse') : this.filterDetailsWrapperTarget.classList.add('flex-xl-row', 'flex-column-reverse')
    } else {
      this.filterDetailsWrapperTarget.classList.add('flex-xl-row', 'flex-column-reverse')
    }
  }

  updateQueryString = (form) => {
    const url = new URL(window.location)
    const formData = new FormData(form)
    const keysToIgnore = ["authenticity_token", "show_filters"]
    const params = url.searchParams

    Array.from(params.keys()).forEach((key) => params.delete(key))
    uniq(Array.from(formData.keys())).forEach((key) => {
      if (keysToIgnore.includes(key)) {
        return
      }

      const values = compact(formData.getAll(key))

      if (values.length) {
        values.forEach((value) => {
          if (value.length) {
            params.append(key, value)
          }
        })
      }
    })

    window.history.pushState({ customHistory: true }, "", url)
  }

  scrollToElement = (selector) => {
    let targetOffset = $(selector).offset().top;

    window.scrollTo({
      top: targetOffset,
    });
  }

  hideInformationBanner = () => {
    const secondaryInfoBanner = document.querySelector(".secondary-info-banner");
    if (secondaryInfoBanner && !secondaryInfoBanner.classList.contains("d-none")) {
      secondaryInfoBanner.classList.add("d-none");
    }
  }

  requestSubmit(event) {
    this.submitFormOnCheckedChange(event)
    localStorage.removeItem('focusedElement');
    if (event.type === 'click' || event.key === 'Enter') {
      this.formTarget.requestSubmit();
      this.restoreFocusOnFiltersModal(event.target.id);
      this.hideInformationBanner();
    }
  }

  initializeTurboStreamEvents() {
    const afterStreamRenderEvent = new Event("turbo:after-stream-render");
    document.addEventListener("turbo:before-stream-render", (event) => {
      const originalRender = event.detail.render;
      event.detail.render = (streamElement) => {
        originalRender(streamElement);
        document.dispatchEvent(afterStreamRenderEvent);
      };
    });
  }

  retainShowAllFiltersState() {
    const showAll = JSON.parse(localStorage.getItem('showAll')) || {};
    Object.keys(showAll).forEach((key) => {
      const showFiltersBtn = document.getElementById(key);
      if (showFiltersBtn && showAll[key]) {
        showFiltersBtn.classList.add("show-less");
        const parentContainer = showFiltersBtn.closest('.gen-filters-block-main');
        const checkBoxWrapperElements = parentContainer.querySelectorAll('.search-filters-checkbox');
        checkBoxWrapperElements.forEach(elem => {
          elem.classList.remove('search-filters-checkbox-toggle');
        });
        const itemsCount = checkBoxWrapperElements.length - 6;
        this.handleToggleShowFilterOptionsBtnText(parentContainer, showFiltersBtn, itemsCount);
      }
    });
  }

  addFocusRestorationListener() {
    document.addEventListener("turbo:after-stream-render", this.restoreFocus.bind(this));
  }

  restoreFocus() {
    this.retainShowAllFiltersState();
    const focusedElementId = localStorage.getItem("focusedElement");
    if (focusedElementId) {
      const element = document.getElementById(focusedElementId);
      if (element) {
        requestAnimationFrame(() => {
        element.focus();
        });
      }
    }
    this.retainShowAllFiltersState();
  }

  setupResizeListener() {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 991) {
        this.searchFiltersBlockTarget.setAttribute('tabindex', '0');
      } else {
        this.searchFiltersBlockTarget.removeAttribute('tabindex');
      }

      this.toggleFilterButtonInnerHTML();
      this.handleEscapeKeyPress();
    };

    window.addEventListener("resize", handleResize);

    handleResize();
    this.handleResize = handleResize;
  }

  bindEscapeKeyListener() {
    if (!this.escapeKeyListener) {
      this.escapeKeyListener = (event) => {
        if (event.key === "Escape" && this.searchFiltersBlockTarget.classList.contains("show")) {
          this.hideFilters();
        }
      };
      document.addEventListener("keydown", this.escapeKeyListener);
    }
  }

  unbindEscapeKeyListener() {
    if (this.escapeKeyListener) {
      document.removeEventListener("keydown", this.escapeKeyListener);
      this.escapeKeyListener = null;
    }
  }

  handleEscapeKeyPress() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 991) {
      this.bindEscapeKeyListener();
    } else {
      this.unbindEscapeKeyListener();
    }
  }

  restoreFocusOnFiltersModal(elementId) {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 991 && this.hasSearchFiltersBlockTarget && this.searchFiltersBlockTarget.classList.contains("show")) {
      localStorage.setItem('focusedElement', elementId);
    } else {
      this.scrollToElement('#search-results-container');
    }
  }

  disconnect() {
    if (this.handleResize) {
      window.removeEventListener("resize", this.handleResize);
    }
  }

  static trapFocus(element) {
    // Set aria-hidden on elements outside the focus trap based on the trigger
    const trigger = document.activeElement;
    if (trigger) {
      const toggleType = trigger.getAttribute("data-toggle");
      if (toggleType === "offcanvas") {
        const mainElement = document.querySelector("main");
        if (mainElement) {
          mainElement.setAttribute("aria-hidden", "true");
        }
      } else if (toggleType === "search-filters-offcanvas") {
        const searchResults = document.querySelector(".search-results-block");
        if (searchResults) {
          searchResults.setAttribute("aria-hidden", "true");
        }
      }
    }

    let focusableElements = this.getFocusableElements(element);
    if (focusableElements.length === 0) {
      return;
    }

    let firstElement = focusableElements[0];
    let lastElement = this.getLastFocusableElement(element);

    const accordions = element.querySelectorAll('.expand-collapse-btn-link');
    if (accordions.length > 0) {
      const lastAccordion = accordions[accordions.length - 1];

      const updateLastElement = () => {
        lastElement = lastAccordion.getAttribute('aria-expanded') === 'true'
          ? this.getLastFocusableElement(element)
          : lastAccordion;
      };

      ['keydown', 'mousedown'].forEach(eventType => {
        lastAccordion.addEventListener(eventType, (event) => {
          if (event.key !== 'Enter') {
            updateLastElement();
          }
        });
      });

      updateLastElement();
    }

    element.addEventListener('keydown', (event) => {
      if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          event.preventDefault();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          event.preventDefault();
        }
      }
    });

    firstElement.focus();
  }

  static getFocusableElements(container) {
    return container.querySelectorAll(
      'a, button, input, textarea, select'
    );
  }

  static getLastFocusableElement(container) {
    const focusableElements = this.getFocusableElements(container);
    return focusableElements.length > 0
      ? focusableElements[focusableElements.length - 1]
      : null;
  }
}
